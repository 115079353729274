import './Game.scss';

export default function Game({ game }) {
  return (
    <div className="game">
      <div className="game-img">
        <img src={game.acf.featured_image.url} alt={game.acf.featured_image.alt} />
      </div>

      <div className="game-content" style={{backgroundColor: game.acf.color_scheme}}>
        <img className="game-logo" src={game.acf.logo.url} alt={game.acf.logo.alt} aria-hidden="true" />

        <div className="game-details">
          <p>{game.acf.preview_excerpt}</p>
          {game.acf.site_url ?
            <a href={game.acf.site_url.url} target="_blank" rel="noreferrer">{game.acf.site_url.title}</a>
            :
            game.acf.platforms.map((site, index) => (
              <a key={index} href={site.link} target="_blank" rel="noreferrer">{site.type}</a>
            ))
          }
        </div>

        <div className="game-platforms">
          {game.acf.available_on.map((platform, index) => (
            <div key={index} className={`${platform.toLowerCase()}`}></div>
          ))}
        </div>
      </div>
    </div>
  )
}
