import './Features.scss'

export default function Features(props) {
  const { data } = props;

  return (
    <section className="features" style={{ backgroundImage: `url(${data.background_image})` }}>
      <section className="subheading">
        <div className="container">
          <div className="subheading-heading" dangerouslySetInnerHTML={{ __html: data.heading }}></div>
          <p dangerouslySetInnerHTML={{ __html: data.body }}></p>
        </div>
      </section>

      <div className="container">
        <ul>
          {data.features_list.map(feature => (
            <li className="feature" key={feature.title}>
              <div className="feature-content">
                <h2>{feature.title}</h2>
                <p>{feature.body}</p>
              </div>

              <div className="feature-img">
                <img src={feature.image.url} alt={feature.image.alt} />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}
