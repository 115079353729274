import Button from 'components/Button/Button'
import './CTAs.scss'

export default function CTAs(props) {
  const { data } = props;

  return (
    <section className="ctas" style={{ backgroundImage: `url(${data.background_image})` }}>
      <div className="container">
        {data.links.map(link => (
          <Button url={link.link.url} target={link.link.target} key={link.link.url}>{link.link.title}</Button>
        ))}
      </div>
    </section>
  )
}
