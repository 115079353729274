import './Leadership.scss';
import LeadershipMobile from './Mobile/Leadership-Mobile';

export default function Leadership(props) {
  const { data } = props;

  const Leader = ({ leader }) => {
    const { name, title, avatar, bio, linkedin } = leader;

    return (
      <div className="leader">
        <div className="avatar">
          <img src={avatar.url} alt={name} />
        </div>
        <div className="leader-info">
          <h3 className="name">{name}</h3>
          <p className="title">{title}</p>
          <div className="wysiwyg bio" dangerouslySetInnerHTML={{ __html: bio }}></div>
          <a className="linkedin" href={linkedin.url} target="_blank" rel="noreferrer">{linkedin.title}</a>
        </div>
      </div>
    )
  }

  return (
    <div className="leadership">
      <h2 className="leadership-headline">Leadership</h2>
      <div className="leadership-members">
        {data.map((leader, index) => <Leader key={index} leader={leader} />)}
      </div> 
      <div className="leadership-members-mobile">
        <LeadershipMobile>
          {data.map((leader, index) => <Leader key={index} leader={leader} />)}
        </LeadershipMobile>
      </div>
    </div>
  )
}