import './Hero.scss'

export default function Hero({ data }) {
  const BackgroundMedia = () => {
    if (data.background_video) {
      return (
        <video poster={data.background_image.url} muted playsInline autoPlay loop>
          <source src={data.background_video.url} type="video/mp4" />
        </video>
      )
    }

    return (
      <>
        <img src={data.background_image} alt={data.background_image.alt} className="hero-bg-desktop" />
        {data.background_image_mobile && <img src={data.background_image_mobile.url} alt={data.background_image_mobile.alt} className="hero-bg-mobile" />}
      </>
    )
  }

  return (
    <div className="hero">
      <div className="background-media">
        <BackgroundMedia />
      </div>

      <div className="container">
        <div className="hero-container" style={{minHeight: `${data.min_height}px`}}>
          <h1 dangerouslySetInnerHTML={{ __html: data.headline }} style={data.headline_color ? { color: data.headline_color } : {}}></h1>
          {data.body &&
            <p dangerouslySetInnerHTML={{ __html: data.body }}></p>
          }
        </div>
      </div>
    </div>
  )
}
