import './Leadership-Mobile.scss';
import Slider from 'react-slick';
import Arrow from '../../../../img/custom-arrow.svg'

export default function LeadershipMobile(props) {
  const { children } = props;

  const CustomArrow = (props) => {
    const { onClick, direction } = props;
    return (
      <button className={`slick-arrow slick-${direction}`} onClick={onClick} >
        <img src={Arrow} alt="arrow" />
      </button>
    )
  }

  const settings = {
    dots: true,
    prevArrow: <CustomArrow direction="left" />,
    nextArrow: <CustomArrow direction="right" />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: (i) => (
      <button className="leadership-mobile__dot">
        <img src={children[i].props.leader.avatar.url} alt={children[i].props.leader.name} />
      </button>
    ),
  };

  return (
    <div className="leadership-mobile">
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  )
}