import { useEffect, useState } from 'react'
import './Hero.scss'
import Rellax from 'rellax';

export default function Hero({ data }) {
  const BackgroundMedia = () => {
    const rellaxInit = () => {
      new Rellax('.rellax', {
        round: true,
        horizontal: true,
      });
    }

    useEffect(() => {
      if (data.parallax) {
        rellaxInit();
      }
    }, []);

    return (
      <>
        {data.parallax_layers.map(layer => (
          <img
            src={layer.image}
            alt=""
            className="parallax-layer rellax"
            data-rellax-vertical-scroll-axis="xy"
            data-rellax-vertical-speed={layer.speed_vertical}
            data-rellax-horizontal-speed={layer.speed_horizontal}
            key={layer.image}
          />
        ))}
      </>
    )
  }

  const Headline = ({ headline }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollPositionStyle, setScrollPositionStyle] = useState(0);

    useEffect(() => {
      window.addEventListener('scroll', () => setScrollPosition(window.pageYOffset), { passive: true });
    }, []);

    useEffect(() => {
      setScrollPositionStyle(1 - (scrollPosition / 1000));
    }, [scrollPosition]);

    return (
      <h1 dangerouslySetInnerHTML={{ __html: headline }} style={{transform: `scale(${scrollPositionStyle})`, opacity: `${scrollPosition < window.innerHeight / 2 ? scrollPositionStyle : 0}`}}></h1>
    )
  }

  return (
    <div className="parallax-hero">
      <Headline headline={data.headline} />

      <div className="parallax-layers">
        <img src={data.background_image} alt="" className="parallax-layer" />
        <BackgroundMedia />
      </div>
    </div>
  )
}
