import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './Home.scss'
import Rellax from 'rellax';

import Footer from 'components/Footer/Footer'

import Hero from './Hero/Hero'
import Features from './Features/Features'
import Games from './Games/Games'
import CTAs from './CTAs/CTAs'

export default function Home() {
  const [rellax, setRellax] = useState(null);
  const [pageContent, setPageContent] = useState({});
  const getPageContent = async () => {
    const pageContentResponse = await fetch(`${API_URL}/pages/14`);
    await setPageContent(await pageContentResponse.json());
  }

  useEffect(() => {
    getPageContent();
  }, []);

  useEffect(() => {
    let $rellax = new Rellax('.rellax-container');
    setRellax($rellax);
  }, [pageContent]);

  useEffect(() => {
    if (!rellax || !rellax.refresh) return;
    setTimeout(() => rellax.refresh(), 1000);
  }, [rellax]);

  if (!('id' in pageContent)) return null;

  const { components } = pageContent.acf;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-home' }}>
        <title>Monumental</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Hero data={components.find(component => component.acf_fc_layout === 'hero')} />

      <div className="rellax-container" data-rellax-speed={6.5}>
        <Features data={pageContent.acf.features} />

        <Games />
        <a href="/games" className="cta-all-games-mobile"><span>See all our games</span></a>

        <CTAs data={pageContent.acf.ctas} />

        <Footer />
      </div>
    </>
  )
}
