import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './Games.scss';

import Hero from 'components/Hero/Hero'
import Game from './Game/Game';
import Footer from 'components/Footer/Footer';

export default function Games(){
  const [pageContent, setPageContent] = useState({});
  const getPageContent = async () => {
    const pageContentResponse = await fetch(`${API_URL}/pages/1243`)
    setPageContent(await pageContentResponse.json())
  }

  const [games, setGames] = useState([]);
  const getGames = async () => {
    const response = await fetch(`${API_URL}/games`);
    const gamesData = await response.json();
    setGames(gamesData);
  }

  useEffect(() => {
    getPageContent();
    getGames();
  }, []);

  if (!('id' in pageContent)) return null;

  const { components } = pageContent.acf;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-games' }}>
        <title>Monumental</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Hero data={components.find(component => component.acf_fc_layout === 'hero')} />

      <section className="games">
        <div className="container">
        {games.filter(game => game.acf.featured).map(game => (
          <Game game={game} key={game.id} />
        ))}

        <div className="games-grid">
          {games.filter(game => !game.acf.featured).map(game => (
            <Game game={game} key={game.id} />
          ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}
