import { useEffect, useState, useContext } from 'react'
import { useLocation, NavLink } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Header.scss'

export default function Header() {
  const location = useLocation();
  const options = useContext(OptionsContext);
  const [menuActive, setMenuActive] = useState(false);
  const toggleMenu = () => {
    setMenuActive(!menuActive);
  }
  const NavItem = (props) => {
    const { navItem } = props;
    const [navDropdownActive, setNavDropdownActive] = useState(false);
    const toggleNavDropdown = () => {
      setNavDropdownActive(!navDropdownActive);
    }

    useEffect(() => {
      setNavDropdownActive(false);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    if (navItem.children) {
      return (
        <li onMouseEnter={() => setNavDropdownActive(true)} onMouseLeave={() => setNavDropdownActive(false)}>
          <button onClick={toggleNavDropdown}>Game</button>

          <ul className={`${navDropdownActive ? 'active' : ''}`}>
            {navItem.children.map(child => (
              <li key={child.link.url}>
                <NavLink to={child.link.url}>{child.link.title}</NavLink>
              </li>
            ))}
          </ul>
        </li>
      )
    }

    return (
      <li>
        <NavLink to={navItem.link.url}>{navItem.link.title}</NavLink>
      </li>
    )
  }

  useEffect(() => {
    setMenuActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <header className="header">
      <nav className="container">
        <div className="header-main">
          <NavLink to="/" className="main-logo">
            <img src={options.logo_primary.url} alt={options.logo_primary.alt} />
          </NavLink>

          <button className="btn-nav" onClick={toggleMenu}>Open navigation</button>
        </div>

        <div className={`${'header-content'} ${menuActive ? 'active' : ''}`} >
          <ul className="header-nav-list">
            {options.primary_nav.map(item => (
              <NavItem navItem={item} key={item.link.title} />
            ))}
          </ul>
        </div>
      </nav>
    </header>
  )
}
