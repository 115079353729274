import { useState, useEffect } from 'react'
import { API_URL } from 'assets';
import './Games.scss'

export default function Games() {
  const [games, setGames] = useState([]);
  const getGames = async () => {
    const response = await fetch(`${API_URL}/games?orderby=date`);
    const gamesData = await response.json();
    setGames(gamesData);
  }
  const [activeGame, setActiveGame] = useState(null);
  const handleGameClick = (e, game) => {
    if (e.target.tagName === 'A') return;
    setActiveGame(activeGame => activeGame === game.id ? null : game.id);
  }

  useEffect(() => {
    getGames();
  }, []);

  if (!games.length) return null;

  return (
    <div className={`games ${activeGame ? 'active' : ''}`}>
      <div className="games-content">
        {games.filter(game => game.acf.featured).map(game => (
          <button className={`game ${activeGame === game.id ? 'active' : ''}`} onClick={(e) => handleGameClick(e, game)} key={game.id}>
            <img src={game.acf.logo_alternate ? game.acf.logo_alternate.url : game.acf.logo.url} className="game-logo-inactive" alt={game.acf.logo.alt} aria-hidden="true" />
            <div className="game-image" style={{ backgroundImage: `url(${game.acf.preview_image.url})` }}></div>
            <video className="game-video" poster={game.acf.preview_image.url} muted playsInline autoPlay loop>
              <source src={game.acf.preview_gameplay.url} type="video/mp4" />
            </video>

            <div className="game-content" style={{ "--colorScheme": game.acf.color_scheme }}>
              <div className="game-details">
                <div className="game-image-cta">
                  <img src={game.acf.logo.url} alt={game.acf.logo.alt} />
                  <div className="game-cta">
                    <p>{game.acf.preview_excerpt}</p>
                    <a href={game.acf.site_url} target="_blank" rel="noreferrer">Visit Site</a>
                  </div>
                </div>
              </div>
            </div>
          </button>
        ))}
      </div>

      <a href="/games" className="cta-all-games"><span>See all our<br /> games</span></a>
    </div>
  )
}
