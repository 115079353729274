import { React } from 'react'
import { Routes, Route } from 'react-router-dom';
import { OptionsContextProvider } from './OptionsContext';

// Components
import Header from './components/Header/Header'

// Pages
import Home from './pages/Home/Home'
import Games from './pages/Games/Games';
import Team from './pages/Team/Team';

function App() {
  return (
    <>
      <OptionsContextProvider>
        <Header />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="games" element={<Games />} />
          <Route path="team" element={<Team />} />
        </Routes>
      </OptionsContextProvider>
    </>
  );
}

export default App;
