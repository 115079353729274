import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { API_URL } from 'assets';
import './Team.scss';

import Hero from 'components/Hero/Hero'
import Leadership from './Leadership/Leadership';
import Footer from 'components/Footer/Footer';

export default function Team() {
  const [pageContent, setPageContent] = useState({});
  const getPageContent = async () => {
    const pageContentResponse = await fetch(`${API_URL}/pages/1365`)
    setPageContent(await pageContentResponse.json())
  }

  useEffect(() => {
    getPageContent();
  }, []);

  if (!('id' in pageContent)) return null;

  const { components } = pageContent.acf;

  return (
    <>
      <Helmet bodyAttributes={{ class: 'page-team' }}>
        <title>Monumental</title>
        <meta name="description" content="Helmet application" />
      </Helmet>

      <Hero data={components.find(component => component.acf_fc_layout === 'hero')} />

      <section className="container team-content">
        <p className="team-headline" dangerouslySetInnerHTML={{ __html: pageContent.acf.content.headline }}></p>

        <Leadership data={pageContent.acf.content.leadership} />
        
      </section>

      <Footer />
    </>
  )
}