import { Link } from 'react-router-dom';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import './Button.scss'

export default function Button(props) {
  const { children, url, target, className, color, anchor } = props;
  const $className = `btn-shape${className ? ' ' + className : ''}${color ? ' ' + color : ''}`;

  const ButtonLink = () => {
    if (target === '_blank') {
      return (
        <a href={url} target={target} className={$className}>
          {children}
        </a>
      )
    }

    if (url) {
      return (
        <Link to={url} target={target} className={$className}>
          {children}
        </Link>
      )
    }

    return (
      <button className={$className}>
        {children}
      </button>
    )
  }


  return (
    <>
      {!anchor &&
        <ButtonLink />
      }

      {anchor &&
        <AnchorLink href={url} className={$className}>
          {children}
        </AnchorLink>
      }
    </>
  )
}
