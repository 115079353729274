import { useContext } from 'react'
import { Link } from 'react-router-dom';
import { OptionsContext } from 'OptionsContext';
import './Footer.scss'

export default function Footer() {
  const options = useContext(OptionsContext);
  const { logo_primary, logo_secondary, copyright, address, email, social_links, secondary_nav } = options;

  const NavLink = (props) => {
    const { item } = props;

    if (item.link.url.indexOf('http') > -1) {
      return (
        <a href={item.link.url} target={item.link.target}>{item.link.title}</a>
      )
    }

    return (
      <Link to={item.link.url}>{item.link.title}</Link>
    )
  }

  return (
    <footer className="footer">
      <div className="container">
        <img className="mntl-logo-mobile" src={logo_primary.url} alt={logo_primary.alt} />

        <div className="col connect">
          <span>Connect With Us</span>
          <a href={`mailto:${email}`} className="email">{email}</a>
          <ul className="social-nav">
            {social_links.map(account => (
              <li key={account.link.title}>
                <a href={account.link.url} target={account.link.target}>
                  <img src={account.icon.url} alt={account.link.title} />
                </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="col mntl">
          <img src={logo_secondary.url} alt={logo_secondary.alt} />
          <p className="monumental">Monumental</p>
          <p className="location">{address}</p>
        </div>

        <div className="col links">
          <ul className="nav-list">
            {secondary_nav.map(item => (
              <li key={item.link.title}>
                <NavLink item={item} />
              </li>
            ))}
          </ul>
          <p className="copyright">{copyright}</p>
        </div>
      </div>
    </footer>
  )
}
